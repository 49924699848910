import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store';
import { Guid } from 'guid-typescript';
import { SharedModule } from '@/feature/Shared/store';

import pgaDiContainer from '@/App.container';
import IResourceService from '@/services/iResourceService';
import ResourceService from '@/services/ResourceService';
import { CertificationIdDate } from '../../../services/iResourceService';

import { MyCertificationsTableView, MyCertificationViewModel, ListCertifications, CertificationModel,
        SelectMyCertificationsCommand, 
        SelectMyCertificationsResponse } from '@/services/iResourceService';

export interface MyCertificationsState
{
    MyCertifications: MyCertificationsTableView;
    MyCertificationsSelect: SelectMyCertificationsModel;

    AllCertificationsTable: ListCertifications;
}

export interface SelectMyCertificationsModel
{
    Command: SelectMyCertificationsCommand | null;
    Response: SelectMyCertificationsResponse | null;
    Errors: Map<string, Array<string>>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'MyCertificationsModule', })
class MyCertificationsStore extends VuexModule implements MyCertificationsState
{
    private resourceService: IResourceService = pgaDiContainer.get<IResourceService>(ResourceService);

    public MyCertifications: MyCertificationsTableView =
    {
        Certifications: new Array<MyCertificationViewModel>(),
    };

    public MyCertificationsSelect: SelectMyCertificationsModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    };

    public AllCertificationsTable: ListCertifications =
    {
        Certifications: new Array<CertificationModel>(),
    }

    // List Certifications Table
    @Action({ rawError: true })
    public async GetCertificationsTable(): Promise<void>
    {
        const certifications: ListCertifications = await this.resourceService.GetAllCertifications();
        this.SET_CERTIFICATIONS_TABLE(certifications);
    }
    @Mutation
    public SET_CERTIFICATIONS_TABLE(certifications: ListCertifications)
    {
        this.AllCertificationsTable = certifications;
    }

    // GET & SET MYCERTIFICATIONS
    @Action({ rawError: true })
    public async GetMyCertifications(): Promise<void>
    {
        const initMyCertifications: MyCertificationsTableView = 
        {
            Certifications: new Array<MyCertificationViewModel>(),
        };
        this.SET_MYCERTIFICATIONS_TABLE(initMyCertifications);
        const certifications: MyCertificationsTableView = await this.resourceService.GetMyCertifications();
        this.SET_MYCERTIFICATIONS_TABLE(certifications);
    }
    @Mutation
    public SET_MYCERTIFICATIONS_TABLE(certifications: MyCertificationsTableView)
    {
        this.MyCertifications = certifications;
    }

    // Select MyCertifications
    @Action({ rawError: true })
    public async SelectCertifications(): Promise<SelectMyCertificationsResponse>
    {
        try
        {
            let response: SelectMyCertificationsResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);

            if (this.MyCertificationsSelect.Command)
                response = await this.resourceService.SelectMyCertifications(this.MyCertificationsSelect.Command);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_MYCERTIFICATION_SELECT_ERRORS(errs);

            throw errs;
        }
    }
    @Action({ rawError: true })
    public InitSelectMyCertifications()
    {
        const command: SelectMyCertificationsCommand =
        {
            Certifications: new Array<CertificationIdDate>(),
        }

        const certificationIdDates: Array<CertificationIdDate> = new Array<CertificationIdDate>();
        this.MyCertifications.Certifications.forEach(c => certificationIdDates.push({ CertificationId: c.Id, AttainmentDate: c.AttainmentDate, ExpireDate: c.ExpireDate, }));

        this.SET_MYCERTIFICATION_SELECT_COMMAND(command);
        this.SET_MYCERTIFICATION_SELECT_CERTIFICATIONS(certificationIdDates);
    }
    @Mutation
    public SET_MYCERTIFICATION_SELECT_COMMAND(command: SelectMyCertificationsCommand)
    {
        this.MyCertificationsSelect.Command = command;
    }
    @Mutation
    public SET_MYCERTIFICATION_SELECT_CERTIFICATIONS(certifications: Array<CertificationIdDate>)
    {
        if(this.MyCertificationsSelect.Command)
            this.MyCertificationsSelect.Command.Certifications = certifications;
    }
    @Mutation
    public SET_MYCERTIFICATION_SELECT_ATTAINMENT(certificationIdDate: CertificationIdDate)
    {
        if(this.MyCertificationsSelect.Command)
            this.MyCertificationsSelect.Command.Certifications.forEach(cd =>
                {
                    if(cd.CertificationId == certificationIdDate.CertificationId)
                        cd.AttainmentDate = certificationIdDate.AttainmentDate;
                });
    }
    @Mutation
    public SET_MYCERTIFICATION_SELECT_EXPIRE(certificationIdDate: CertificationIdDate)
    {
        if(this.MyCertificationsSelect.Command)
            this.MyCertificationsSelect.Command.Certifications.forEach(cd =>
                {
                    if(cd.CertificationId == certificationIdDate.CertificationId)
                        cd.ExpireDate = certificationIdDate.ExpireDate;
                });
    }
    @Mutation
    public SET_MYCERTIFICATION_SELECT_ERRORS(errors: Map<string, Array<string>>)
    {
        this.MyCertificationsSelect.Errors = new Map<string, Array<string>>(errors);
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();

    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }

    return errs;
}

export const MyCertificationsStoreDefault = getModule(MyCertificationsStore);