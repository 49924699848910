














































import { Model, Prop } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { SelectMyCertificationsCommand, MyCertificationViewModel, CertificationIdDate, VendorModel } from '@/services/iResourceService';
import { MyCertificationsStoreDefault } from '../store';
import { Guid } from 'guid-typescript';

import PgaCertificationDatePicker from "@/feature/Resource/Certifications/Components/PgaCertificationDatePicker.vue";

@Component( {
	components:
	{
		PgaCertificationDatePicker,
	}
} )
export default class PgaMyCertificationsSelect extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: SelectMyCertificationsCommand;
	@Prop( { default: false } ) readonly isLoading!: boolean;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	headers: Array<any> = [
		{
			text: "Code",
			align: "start",
			sortable: true,
			value: "Code",
			width: "10%",
		},
		{
			text: "Description",
			align: "start",
			sortable: true,
			value: "Description",
		},
		{
			text: "Vendor",
			align: "start",
			sortable: true,
			value: "Vendor.BusinessName",
		},
		{
			text: "Active",
			align: "start",
			sortable: false,
			value: "IsActive",
		},
		{
			text: "Attainment Date",
			align: "start",
			sortable: false,
			value: "AttainmentDate",
			width: "25%",
		},
		{
			text: "Expire Date",
			align: "start",
			sortable: false,
			value: "ExpireDate",
		},
	];

	get listCertifications (): Array<MyCertificationViewModel>
	{
		const listCertificationDates: Array<MyCertificationViewModel> = new Array<MyCertificationViewModel>( ...MyCertificationsStoreDefault.MyCertifications.Certifications );
		const listCertificationIdDates: Array<Guid> = listCertificationDates.map( u => u.Id );

		MyCertificationsStoreDefault.AllCertificationsTable.Certifications.forEach( c =>
		{
			const attainmentDate = new Date( Date.now() );
			const expireDate = new Date( Date.now() );
			expireDate.setFullYear( expireDate.getFullYear() + 2 )
			if ( !listCertificationIdDates.includes( c.Id ) )
				listCertificationDates.push(
					{
						Id: c.Id,
						Code: c.Code,
						Description: c.Description,
						Vendor: c.Vendor,
						Type: c.Type,
						IsActive: c.IsActive,
						AttainmentDate: attainmentDate,
						ExpireDate: expireDate
					} );
		} );

		return listCertificationDates;
	}

	get selectedMyCertifications (): Array<MyCertificationViewModel>
	{
		const vendorEmpty: VendorModel =
		{
			Id: Guid.createEmpty(),
			Code: "",
			BusinessName: "",
			Status: 0,
		};

		const selectedCertificationIdDates: Array<MyCertificationViewModel> = new Array<MyCertificationViewModel>();
		MyCertificationsStoreDefault.MyCertificationsSelect.Command?.Certifications
			.forEach( cd =>
				selectedCertificationIdDates.push(
					{
						Id: cd.CertificationId, Code: "",
						Vendor: vendorEmpty,
						Type: 0,
						IsActive: false,
						Description: '',
						AttainmentDate: cd.AttainmentDate,
						ExpireDate: cd.ExpireDate,
					} ) );

		return selectedCertificationIdDates ?? new Array<MyCertificationViewModel>();
	}
	set selectedMyCertifications ( value: Array<MyCertificationViewModel> )
	{
		const certificationIdDates: Array<CertificationIdDate> = new Array<CertificationIdDate>();
		value.forEach( c => certificationIdDates.push( { CertificationId: c.Id, AttainmentDate: c.AttainmentDate, ExpireDate: c.ExpireDate, } ) );

		MyCertificationsStoreDefault.SET_MYCERTIFICATION_SELECT_CERTIFICATIONS( certificationIdDates );
	}

	showDate ( userId: Guid ): boolean
	{
		return this.selectedMyCertifications.map( u => u.Id ).includes( userId );
	}

	setAttainmentDate ( certificationId: Guid, date: Date ): void
	{
		const certificationIdDate: CertificationIdDate =
		{
			CertificationId: certificationId,
			AttainmentDate: date,
			ExpireDate: null,
		};
		MyCertificationsStoreDefault.SET_MYCERTIFICATION_SELECT_ATTAINMENT( certificationIdDate );
	}

	setExpireDate ( certificationId: Guid, date: Date ): void
	{
		const certificationIdDate: CertificationIdDate =
		{
			CertificationId: certificationId,
			AttainmentDate: new Date(),
			ExpireDate: date,
		};
		MyCertificationsStoreDefault.SET_MYCERTIFICATION_SELECT_EXPIRE( certificationIdDate );
	}

	get Errors (): Map<string, Array<string>>
	{
		return MyCertificationsStoreDefault.MyCertificationsSelect.Errors;
	}
}
