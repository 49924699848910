













import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { MyCertificationsStoreDefault, SelectMyCertificationsModel } from './store';
import PgaMyCertificationsSelect from './Components/PgaMyCertificationsSelect.vue';

@Component({
    components:
    {
		PgaMyCertificationsSelect
	}
})

export default class MyCertificationsPageSelect extends mixins(SharedMixin)
{
	private isLoading = true;
	
	get MyCertificationsSelects() : SelectMyCertificationsModel
	{
		return MyCertificationsStoreDefault.MyCertificationsSelect;
	}

	async Selects() : Promise<void>
	{
		try
		{
			await MyCertificationsStoreDefault.SelectCertifications();
			this.$router.push({name: 'mycertificationsindex'});
		}
		// eslint-disable-next-line no-empty
		catch(error) { }
	}

	undo() : void 
	{
		this.$router.go(-1);
    }
    
	async mounted() : Promise<void>
	{
		this.isLoading = true;
		MyCertificationsStoreDefault.InitSelectMyCertifications();
		await MyCertificationsStoreDefault.GetCertificationsTable();
		this.isLoading = false;
	}

	beforeDestroy(): void
	{
		MyCertificationsStoreDefault.MyCertificationsSelect.Errors = new Map<string, Array<string>>();
	}
}
